<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay
          :show="loader"
          rounded="sm"
          variant="dark"
          :opacity="0.1"
        >
          <b-card title="Create New Model">
            <validation-observer ref="createModelValidator">
              <b-form @submit.prevent="onSubmit">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Category"
                      label-for="category"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="category"
                        rules="required"
                      >
                        <b-form-select
                          v-model="form.mobile_category_id"
                          :options="categoryOptions"
                          @change="getBrands"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Brand"
                      label-for="brand"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="brand"
                        rules="required"
                      >
                        <b-form-select
                          v-model="form.mobile_brand_id"
                          :options="options"
                          :disabled="!form.mobile_category_id"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Name"
                      label-for="name"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="form.name"
                          name="name"
                          placeholder="Enter model name"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Priority"
                      label-for="priority"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="priority"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="form.priority"
                          type="number"
                          placeholder="Enter priority"
                        />
                        <small class="text-muted mt-1">
                          Smaller the number, greater the priority!
                        </small>
                        <br>
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="secondary"
                        type="button"
                        class="mr-1"
                        @click="clearForm"
                      >
                        Reset
                      </b-button>
                      <b-button
                        variant="success"
                        type="submit"
                      >
                        Create
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const mobileTabletModule = createNamespacedHelpers('mobileTablet')

export default {
  data() {
    return {
      loader: false,
      form: {
        name: '',
        priority: null,
        mobile_brand_id: null,
        mobile_category_id: null,
      },
      options: [
        { value: null, text: 'Please select brand', disabled: true },
      ],
      categoryOptions: [
        { value: null, text: 'Please select category', disabled: true },
      ],
    }
  },
  async mounted() {
    this.loader = true
    await this.getCategories()
    this.loader = false
  },
  methods: {
    ...mobileTabletModule.mapActions(['SHOW_ALL_CATEGORY', 'SHOW_BRANDS_BY_CATEGORY', 'ADD_MODEL']),

    async getCategories() {
      try {
        this.loader = true
        const resp = await this.SHOW_ALL_CATEGORY()
        resp.forEach(element => {
          this.categoryOptions.push({ value: element.id, text: element.name })
        })
        this.loader = false
      } catch (error) {
        this.loader = false
      }
    },

    async getBrands() {
      try {
        this.loader = true
        this.options = [
          { value: null, text: 'Please select brand', disabled: true },
        ]
        this.form.mobile_brand_id = null
        const resp = await this.SHOW_BRANDS_BY_CATEGORY(this.form.mobile_category_id)
        resp.forEach(element => {
          this.options.push({ value: element.id, text: element.name })
        })
        this.loader = false
      } catch (error) {
        this.loader = false
      }
    },

    onSubmit() {
      this.$refs.createModelValidator.validate().then(async success => {
        if (success) {
          try {
            this.loader = true
            const resp = await this.ADD_MODEL(this.form)
            if (resp) {
              this.form.name = ''
              this.form.priority = null
              this.form.mobile_brand_id = null
              this.form.mobile_category_id = null
              this.$nextTick(() => {
                this.$refs.createModelValidator.reset()
              })
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Model created!',
                  icon: 'checkIcon',
                  variant: 'success',
                  text: 'The model has been created successfully!',
                },
              })
            }
            this.loader = false
          } catch (error) {
            this.loader = false
          }
        }
      })
    },
    clearForm() {
      this.form.name = ''
      this.form.priority = null
      this.$nextTick(() => {
        this.$refs.createModelValidator.reset()
      })
    },
  },
}
</script>
